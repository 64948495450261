import React, { useState } from 'react'
import './App.css'
import { ThemeProvider } from '@mui/material/styles'
import { Box, Button, Card, CardActions, Divider, CardContent, Container, Grid, Typography, Link } from '@mui/material'
import {
  TravelExplore,
  CardGiftcard,
  Celebration,
  Hotel,
  GolfCourse,
  Nightlife,
  OpenInNew,
  ArrowBack,
  EmailRounded
} from '@mui/icons-material'
import theme from './theme'

import RSVPComponent from './rsvp'

const activities = [
  {
    name: 'Newfields, Indy’s Art Museum',
    description:
      "Explore immersive art experiences at The Lume, featuring Dali Alive, in Indianapolis' premier art museum.",
    url: 'https://discovernewfields.org/'
  },
  {
    name: 'Kurt Vonnegut Museum & Library',
    description:
      'Dive into the literary world of Kurt Vonnegut at this museum and library dedicated to his work and legacy.',
    url: 'https://www.vonnegutlibrary.org/'
  },
  {
    name: 'Bottleworks District',
    description:
      'Discover the vibrant community hub of dining, entertainment, and shopping at the Bottleworks District.',
    url: 'https://www.bottleworksdistrict.com/'
  },
  {
    name: 'The Inferno Room',
    description: 'Enjoy a unique 21+ Tiki Bar experience with exotic cocktails and a captivating ambiance.',
    url: 'https://theinfernoroom.com/'
  },
  {
    name: 'Tappers Arcade Bar',
    description:
      'Relive the golden age of arcades with classic games and a great selection of drinks at this 21+ barcade.',
    url: 'https://www.tappersarcadebar.com/'
  },
  {
    name: 'Café Patachou',
    description:
      'Start your day right with a visit to Café Patachou, offering the best breakfast and brunch on the northside.',
    url: 'https://www.cafepatachou.com/'
  },
  {
    name: 'Rize Restaurant',
    description: 'Enjoy innovative breakfast and brunch dishes made with locally sourced ingredients at Rize.',
    url: 'https://www.rize-restaurant.com/'
  },
  {
    name: 'Indie Coffee Roasters',
    description: 'The go-to spot for exceptional local coffee, where quality and community meet.',
    url: 'https://www.indiecoffeeroasters.com/'
  },
  {
    name: 'Provider',
    description: 'Sip on good coffee and long drinks in a cozy, downtown atmosphere at Provider.',
    url: 'https://providerindy.com/'
  },
  {
    name: 'Shapiro’s Delicatessen',
    description: "A Kosher deli and an Indy institution, Shapiro's serves up classic deli fare with a rich history.",
    url: 'https://shapiros.com/'
  },
  {
    name: 'Root & Bone',
    description: 'Savor Southern-inspired dishes in a cozy mid-town setting at Root & Bone.',
    url: 'https://www.rootnboneindy.com/'
  },
  {
    name: 'Delicia',
    description: 'Experience a fusion of Latin American flavors in a stylish mid-town setting.',
    url: 'https://www.deliciaindy.com/'
  },
  {
    name: 'King Dough',
    description: 'Enjoy artisanal pizzas made with high-quality, local ingredients at King Dough, northside.',
    url: 'https://carmel.kingdoughpizzas.com/'
  },
  {
    name: 'Kona Jack’s',
    description: 'Best sushi and fresh fish market in the northside, offering a wide selection of seafood.',
    url: 'https://jacksarebetter.net/'
  },
  {
    name: 'Bluebeard',
    description: 'Indulge in modern Midwestern cuisine in a historic setting at Bluebeard, downtown.',
    url: 'https://bluebeardindy.com/'
  },
  {
    name: 'Beholder',
    description: 'Discover the unique flavors of modern cuisine at Beholder, a downtown culinary gem.',
    url: 'https://beholderindy.com/'
  },
  {
    name: 'Oakleys Bistro',
    description: 'Experience innovative and seasonal dishes at Oakleys Bistro, a northside culinary treasure.',
    url: 'http://www.oakleysbistro.com/'
  },
  {
    name: "Anthony's Chophouse",
    description: "Savor upscale steaks and seafood in a sophisticated setting at Anthony's Chophouse, northside.",
    url: 'https://anthonyschophouse.com/'
  }
]

const openInNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer')
}

function App() {
  const [page, setPage] = useState('home')
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Container maxWidth="lg">
          <Grid container spacing={0} className="fadeIn">
            <Grid item xs={6}>
              <Typography
                variant="h2"
                sx={{ fontWeight: 900, cursor: 'pointer' }}
                color="primary"
                component="h1"
                onClick={() => setPage('home')}
              >
                jenntopher
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                textAlign: 'right'
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: 900 }} component="h1" color="primary">
                <Celebration />
                04.20.2024
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {page === 'home' && (
        <Container maxWidth="lg" id="content-wrapper" className="fadeIn">
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: 'flex', // Enable Flexbox
                  justifyContent: 'center', // Center horizontally
                  alignItems: 'center'
                }}
              >
                <Card variant="outlined" sx={{ width: '100%' }}>
                  <CardContent>
                    <Box
                      component="img"
                      sx={{
                        width: { xs: 400, sm: 900 },
                        maxWidth: '100%'
                      }}
                      alt="Jenntopher faces"
                      src="jandc.png"
                    />

                    <Typography variant="h5" component="h3" textAlign="center" color="primary" mb={2} fontWeight={700}>
                      Join us in celebrating with Jennie Slaff & Christopher Reynolds
                    </Typography>
                    <Typography variant="h6" color="primary" component="p" textAlign="center">
                      Saturday, April 20, 2024
                    </Typography>
                    <Typography variant="h6" color="primary" component="p" textAlign="center">
                      Ceremony at 4:20 PM
                    </Typography>
                    <Typography variant="h6" color="primary" component="p" textAlign="center">
                      Reception to Follow
                    </Typography>
                    <Typography variant="h6" color="primary" component="p" textAlign="center" mb={2}>
                      Semi-formal attire
                    </Typography>
                    <Typography variant="h6" component="p" color="primary" textAlign="center">
                      Woodstock Club
                    </Typography>
                    <Typography variant="h6" component="p" color="primary" textAlign="center">
                      1301 W. 38th Street
                    </Typography>
                    <Typography variant="h6" component="p" color="primary" textAlign="center">
                      Indianapolis, IN 46208
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button fullWidth variant="contained" endIcon={<EmailRounded />} onClick={() => setPage('rsvp')}>
                RSVP
              </Button>
              <Button
                fullWidth
                variant="contained"
                onClick={() =>
                  openInNewTab(
                    'https://www.myregistry.com/wedding-registry/jennie-slaff-and-christopher-reynolds-carmel-in/4073317'
                  )
                }
                sx={{ my: 1 }}
                color="primary"
                endIcon={<CardGiftcard />}
              >
                Our Registry
              </Button>

              <Card variant="outlined" sx={{ my: 2 }}>
                <CardContent>
                  <Typography variant="h4" color="primary" fontWeight={900} gutterBottom>
                    Details
                  </Typography>
                  <CardActions>
                    <Box>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => openInNewTab('https://maps.app.goo.gl/euDhNdVpnsxa7iHx7')}
                        endIcon={<TravelExplore />}
                        color="primary"
                        sx={{ mb: 1 }}
                      >
                        Directions
                      </Button>

                      <Button fullWidth variant="contained" onClick={() => setPage('lodging')} endIcon={<Hotel />}>
                        Lodging
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => setPage('itinerary')}
                        endIcon={<Nightlife />}
                        sx={{ mb: 1 }}
                      >
                        Itinerary
                      </Button>
                      <Button
                        fullWidth
                        variant="contained"
                        endIcon={<GolfCourse />}
                        onClick={() => setPage('activities')}
                      >
                        Activities
                      </Button>
                    </Box>
                  </CardActions>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}

      {page === 'lodging' && (
        <Container maxWidth="lg" id="lodging-wrapper">
          <Grid container spacing={2} mt={2} className="fadeIn">
            <Grid item xs={11}>
              <Typography variant="h3" color="primary" fontWeight={700} component="h1" mb={2}>
                Lodging
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {/* <Divider sx={{ mt: 1, mb: 2 }} /> */}
              <Typography variant="h5" color="primary" mb={1} fontWeight={700}>
                Sheraton @ Keystone Crossing
              </Typography>
              <Typography variant="body1" color="primary" component="p" mb={2}>
                8787 Keystone Crossing, Indianapolis, IN 46240
              </Typography>
              <Button
                onClick={() =>
                  openInNewTab(
                    'https://www.marriott.com/event-reservations/reservation-link.mi?id=1705077097381&key=GRP&app=resvlink'
                  )
                }
                color="primary"
                variant="contained"
                endIcon={<OpenInNew />}
              >
                Reynolds/Slaff Wedding Block
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ mt: 1, mb: 2 }} />
              <Typography variant="h5" color="primary" mb={1} fontWeight={700}>
                Hotel Carmichael
              </Typography>
              <Typography variant="body1" color="primary" component="p" mb={2}>
                1 Carmichael Square, Carmel, IN 46032
              </Typography>
              <Button
                onClick={() => openInNewTab('https://www.hotelcarmichael.com/')}
                color="primary"
                endIcon={<OpenInNew />}
                variant="contained"
              >
                Visit Website
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ mt: 1, mb: 2 }} />
              <Typography variant="h5" color="primary" mb={1} fontWeight={700}>
                Ironworks Hotel
              </Typography>
              <Button
                onClick={() => openInNewTab('https://www.ironworkshotelindy.com/')}
                color="primary"
                variant="contained"
                endIcon={<OpenInNew />}
              >
                Visit Website
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ mt: 1, mb: 2 }} />
              <Typography variant="h5" color="primary" mb={1} fontWeight={700}>
                Renaissance Indianapolis North
              </Typography>
              <Button
                onClick={() =>
                  openInNewTab(
                    'https://www.marriott.com/en-us/hotels/indbr-renaissance-indianapolis-north-hotel/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0'
                  )
                }
                color="primary"
                endIcon={<OpenInNew />}
                variant="contained"
              >
                Visit Website
              </Button>
            </Grid>
            <Box mt={2}>
              <Button
                size="small"
                color="primary"
                onClick={() => setPage('home')}
                sx={{ mt: 2 }}
                startIcon={<ArrowBack />}
              >
                Back
              </Button>
            </Box>
          </Grid>
        </Container>
      )}

      {page === 'itinerary' && (
        <Container maxWidth="lg" id="itinerary-wrapper">
          <Grid container spacing={2} mt={2} className="fadeIn">
            <Grid item xs={12}>
              <Card elevation={0} variant="outlined">
                <CardContent sx={{ p: 4 }}>
                  <Typography variant="h3" color="primary" fontWeight={700} component="h1" mb={2}>
                    Itinerary
                  </Typography>
                  <Typography variant="h5" color="primary" mb={1} fontWeight={700}>
                    Thursday, April 18th
                  </Typography>
                  <Typography variant="body1" color="primary">
                    7PM - Out of town Guests & Family - Pizza @ Barb&apos;s
                  </Typography>

                  <Divider sx={{ my: 2, mx: 2 }} />

                  <Typography variant="h5" color="primary" mb={1} fontWeight={700}>
                    Friday, April 19th
                  </Typography>
                  <Typography variant="body1" color="primary">
                    10 AM-12:30 PM - Family Golf Scramble @{' '}
                    <Link
                      href="https://thebridgewaterclub.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      color="primary"
                      fontWeight={900}
                    >
                      Bridgewater
                    </Link>
                  </Typography>
                  <Typography variant="body1" color="primary">
                    12:30 PM - Golf Prizes & Lunch @ The Green Jacket
                  </Typography>

                  <Typography variant="body1" color="primary">
                    4:30 PM - Wedding Rehearsal @ Woodstock
                  </Typography>
                  <Typography variant="body1" color="primary">
                    6 PM - Rehearsal Dinner (Wedding Party) @{' '}
                    <Link
                      href="https://thebridgewaterclub.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      color="primary"
                      fontWeight={900}
                    >
                      Bridgewater Legacy Room
                    </Link> (Business Casual, no jeans)
                  </Typography>
                  <Typography variant="body1" color="primary">
                    7 PM - Family and Wedding Party Cocktails and Cards @{' '}
                    <Link
                      href="https://thebridgewaterclub.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      color="primary"
                      fontWeight={900}
                    >
                      Bridgewater Legacy Room
                    </Link>
                  </Typography>
                  <ul style={{ marginTop: 0 }}>
                    <li>
                      {' '}
                      <Typography variant="body1" color="primary">
                        <em>Clear the Deck</em> Card Tournament
                      </Typography>
                    </li>
                    <li>
                      <Link
                        href="https://clear-the-deck.com/the-rules/"
                        fontWeight={900}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Clear the Deck Rules
                      </Link>
                    </li>
                  </ul>

                  <Typography variant="body1" color="primary" ml={7}></Typography>

                  <Divider sx={{ my: 2, mx: 2 }} />

                  <Typography variant="h5" color="primary" fontWeight={700} mb={1}>
                    Saturday, April 20th
                  </Typography>
                  <Typography variant="body1" color="primary">
                    11 AM - 1PM Wedding Party Preparation @ Woodstock
                  </Typography>
                  <Typography variant="body1" color="primary">
                    1-3 PM - Photos
                  </Typography>
                  <Typography variant="body1" color="primary">
                    2:30 PM - Family arrives for photos / Family photos
                  </Typography>
                  <Typography variant="body1" color="primary">
                    4:20 PM - Ceremony Time
                  </Typography>
                  <Typography variant="body1" color="primary">
                    4:45 PM - Cocktail Hour
                  </Typography>
                  <Typography variant="body1" color="primary">
                    6 PM - Dinner
                  </Typography>
                  <Typography variant="body1" color="primary">
                    6:45 PM - Cake cutting
                  </Typography>
                  <Typography variant="body1" color="primary">
                    7:30 PM - Champagne Toast/Dessert Served
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Box mt={2}>
              <Button
                size="small"
                color="primary"
                onClick={() => setPage('home')}
                sx={{ mt: 2 }}
                startIcon={<ArrowBack />}
              >
                Back
              </Button>
            </Box>
          </Grid>
        </Container>
      )}

      {page === 'rsvp' && <RSVPComponent />}

      {page === 'activities' && (
        <Container maxWidth="lg" id="lodging-wrapper">
          <Grid container spacing={2} mt={2} className="fadeIn">
            <Grid item xs={11}>
              <Typography variant="h3" color="primary" fontWeight={700} component="h1" mb={2}>
                Activities
              </Typography>
              <Grid container spacing={4}>
                {activities.map((activity) => (
                  <>
                    <Grid item xs={12} sm={4}>
                      <Card variant="outlined">
                        <CardContent>
                          <Typography variant="h5" color="primary" fontWeight={700} component="h1" mb={2}>
                            {activity.name}
                          </Typography>
                          <Typography variant="body1" color="primary" component="p" mb={2}>
                            {activity.description}
                          </Typography>
                          <Button
                            onClick={() => openInNewTab(activity.url)}
                            variant="contained"
                            color="primary"
                            size="small"
                            endIcon={<OpenInNew />}
                          >
                            Learn More
                          </Button>
                        </CardContent>
                      </Card>
                    </Grid>
                  </>
                ))}
              </Grid>
            </Grid>
            <Box mt={2}>
              <Button
                size="small"
                color="primary"
                onClick={() => setPage('home')}
                sx={{ mt: 2 }}
                startIcon={<ArrowBack />}
              >
                Back
              </Button>
            </Box>
          </Grid>
        </Container>
      )}
    </ThemeProvider>
  )
}

export default App
