import React, { useState } from 'react'
import emailjs from '@emailjs/browser'
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel
} from '@mui/material'

const RSVPComponent = () => {
  const [formData, setFormData] = useState({
    user_email: '',
    first_name: '',
    last_name: '',
    attending: 'attending'
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs.sendForm('service_rpzg1gq', 'template_mtajzvw', e.target, 'N3nuHUkStrjq87coP').then(
      (result) => {
        console.log(result.text)
        alert('RSVP sent successfully!')
        setFormData({
          user_email: '',
          first_name: '',
          last_name: '',
          attending: 'attending'
        }) // Reset the form fields after successful submission
      },
      (error) => {
        console.log(error.text)
      }
    )
  }

  return (
    <Container maxWidth="lg" id="rsvp-wrapper">
      <Grid container spacing={2} mt={2} className="fadeIn">
        <Grid item xs={12}>
          <Card elevation={0} variant="outlined">
            <CardContent sx={{ p: 4 }}>
              <Grid item xs={12}>
                <Typography variant="h3" color="primary" fontWeight={700} component="h1">
                  RSVP
                </Typography>
              </Grid>
              <form onSubmit={sendEmail}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="First Name"
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleChange}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Last Name"
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleChange}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Your Email"
                      name="user_email"
                      value={formData.user_email}
                      onChange={handleChange}
                      margin="small"
                      required
                    />
                  </Grid>
                </Grid>
                <FormControl component="fieldset" margin="normal">
                  <FormLabel component="legend">Will you be attending?</FormLabel>
                  <RadioGroup row name="attending" value={formData.attending} onChange={handleChange}>
                    <FormControlLabel value="attending" control={<Radio />} label="Attending" />
                    <FormControlLabel value="not_attending" control={<Radio />} label="Not Attending" />
                  </RadioGroup>
                </FormControl>
                <Box mt={2}>
                  <Button type="submit" variant="contained" color="primary" fullWidth>
                    Send RSVP
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}

export default RSVPComponent
