import { createTheme, responsiveFontSizes } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff7b5c'
    },
    secondary: {
      main: '#b9d6d7'
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          // borderRadius: 0,
          textTransform: 'none',
          fontWeight: 700,
          padding: '0.75rem 1.5rem',
          fontSize: '1.2rem',
          color: '#fff'
        }
      }
    }
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(',')
  }
})

export default responsiveFontSizes(theme)
